const body = document.getElementById('body')
const btnMenuOpen = document.getElementById('btn-menu-open')
const btnMenuClose = document.getElementById('btn-menu-close')
const navHeader = document.getElementById('nav-header-mobile')
const navHeaderlinks = document.querySelectorAll('.nav-header-item')
const btnSearchOpen = document.getElementById('btn-search-open')

//open nav-header
btnMenuOpen.addEventListener('click', function () {
    body.classList.toggle("modal")
    navHeader.classList.toggle("open")
    body.scrollTop
    if (window.scrollY) {
        window.scroll(0, 0)  // reset the scroll position to the top left of the document.
    }
})

//close nav-header
btnMenuClose.addEventListener('click', function () {
    body.classList.add("modal")
    navHeader.classList.add("open")
})
document.addEventListener('click', (e) => {
    const close = e.composedPath().includes(btnMenuClose);
    if ((!navHeader.contains(e.target) && !e.target.closest('#btn-menu-open')) || close) {
        navHeader.classList.remove("open")
        body.classList.remove('modal')
    }
})

//open sub-list menu open
navHeaderlinks.forEach(li => {
    li.addEventListener('click', function () {
        navHeaderlinks.forEach(i => i.classList.remove('open'))
        this.classList.add('open')
    })
})

//open sub-list menu close
navHeader.addEventListener('click', function (e) {
    if (!e.target.closest('.nav-header-item')) {
        navHeaderlinks.forEach(i => i.classList.remove('open'))
    }
})

// scroll sidebar fixed
let scrollSidebarFixed = document.querySelectorAll('.scroll-sidebar-fixed')
// console.log(scrollSidebarFixed);
if (scrollSidebarFixed.length) {
    body.addEventListener("scroll", (event) => {
        let fixed = false
        scrollSidebarFixed.forEach(e => {
            let boxFixed = e.querySelector('.sidebar > .re')
            if (20 > e.getBoundingClientRect().top && e.getBoundingClientRect().bottom >= boxFixed.offsetHeight + 20) {
                if (!fixed) {
                    fixed = true;
                    // console.log('fixed')
                    boxFixed.style.position = 'sticky'
                    boxFixed.style.top = '70px'
                }
            } else {
                fixed = false;
                boxFixed.style.position = 'relative'
                boxFixed.style.removeProperty('top')
            }
        })
    });
}

btnSearchOpen.addEventListener('click', () => {
    document.getElementById('search').classList.toggle('show')
})

lazyload()
function lazyload() {
    // window.onload = () => {
    const options = {
        // root: null,
        root: document.querySelector("body"),
        rootMargin: '0px 0px 2000px 0px',
        threshold: 0
    }

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            // console.log(entry.target);
            if (entry.isIntersecting) {

                const lazyWrapImg = entry.target
                const lazyImg = lazyWrapImg.querySelector('img')
                lazyImg.src = lazyImg.dataset.src;
                lazyImg.srcset = lazyImg.dataset.srcset;
                let wrap = lazyImg.parentElement.closest('.content-image')
                // console.log('lazyWrapImg div = ',  lazyWrapImg )

                if(lazyWrapImg.classList.contains('content-image') ){
                    let source = lazyWrapImg.nextElementSibling
                    if(source.classList.contains('img-source')){
                        source.innerHTML = atob(source.dataset.base)
                        source.removeAttribute('data-base')
                    }
                }

                    observer.unobserve(lazyImg)
            }
        })
    }, options)

    const arr = document.querySelectorAll('.wrap-lazy')
    arr.forEach(i => {
        observer.observe(i)
    })
    // }
}
